import * as Sentry from '@sentry/vue'
import { App } from 'vue'
import env from '@/env'
import router from '@/router'

/**
 * Wrapper function to initialize Sentry.
 *
 * @param app The Vue application instance.
 */
const setupSentry = ( app: App<Element> ) => {
  if ( env === 'local' || env === 'development' || env === 'production' ) {
    Sentry.init( {
      app,
      logErrors: true,
      dsn: 'https://18443d88c7674a4736ac2616c393b532@o4507657085386752.ingest.us.sentry.io/4507657089318912',
      tracePropagationTargets: [ 'localhost', 'dev.soundstage.studio', 'app.soundstage.studio', /^\// ],
      integrations: [
        Sentry.browserTracingIntegration( { router } ),
        Sentry.replayIntegration(),
        Sentry.browserProfilingIntegration()
      ],
      tracesSampleRate: 0.2,
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
    } )
  }
}

/**
 * Add metadata to the Sentry request.
 */
const configureSentryScope = ( userId: string, userEmail: string, appVersion: string ) => {
  const scope = Sentry.getCurrentScope()
  scope.setUser( { id: userId, email: userEmail } )
  scope.setContext( 'app', { version: appVersion } )
}

export default setupSentry
export { configureSentryScope }
