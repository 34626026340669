import {
  App,
  URLOpenListenerEvent
} from '@capacitor/app'
import {
  createRouter,
  createWebHistory
} from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import AppTabs from '@/components/AppTabs.vue'

const { supabase } = useDB()

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    component: () => import ( '@/views/PageLogin.vue' ),
    meta: { ignoreAuth: true }
  },
  {
    path: '/lost-password',
    component: () => import ( '@/views/PageLostPassword.vue' ),
    meta: { ignoreAuth: true }
  },
  {
    path: '/request-an-invite',
    component: () => import ( '@/views/PageRequestAnInvite.vue' ),
    meta: { ignoreAuth: true }
  },
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/settings',
    component: () => import( '@/views/PageSettings.vue' ),
  },
  {
    path: '/settings/profile-settings',
    component: () => import( '@/views/PageProfileSettings.vue' ),
  },
  {
    path: '/settings/account-settings',
    component: () => import( '@/views/PageAccountSettings.vue' ),
  },
  {
    path: '/auth/confirm/:token/:type',
    meta: {
      ignoreAuth: true
    },
    component: () => import( '@/views/PageAuthConfirm.vue' ),
  },
  {
    path: '/projects/:id',
    component: () => import( '@/views/PageSingleProject.vue' ),
  },
  {
    path: '/',
    component: AppTabs,
    children: [
      {
        path: '',
        redirect: '/home',
      },
      {
        path: 'home',
        component: () => import( '@/views/PageHome.vue' ),
      },
      {
        path: 'sketchbook',
        component: () => import( '@/views/PageSketchbook.vue' ),
      },
      {
        path: 'projects',
        component: () => import( '@/views/PageProjects.vue' ),
      },
      {
        path: 'activity',
        component: () => import( '@/views/PageActivity.vue' ),
      },
      {
        path: '/route-checker',
        component: () => import( '@/views/PageRouteChecker.vue' ),
      },
      {
        path: 'component-library',
        component: () => import( '@/views/ComponentLibraryPage.vue' ),
      },
    ],
  },
]

const router = createRouter( {
  history: createWebHistory( import.meta.env.BASE_URL ),
  routes
} )

router.beforeEach( async ( to, from, next ) => {
  if ( to.matched.some( record => record.meta.ignoreAuth ) ) {
    next()
  } else {
    const { data } = await supabase.auth.getSession()
    if ( data.session ) {
      next()
    } else {
      next( { path: '/login' } )
    }
  }
} )

App.addListener( 'appUrlOpen', ( event: URLOpenListenerEvent ) => {
  // Full URL: https://dev.soundstage.studio/path?param1=value1&param2=value2
  const url = new URL( event.url )

  // Get the full path including query parameters
  const slug = url.pathname + url.search // Combine the path and query string

  // We only push to the route if there is a slug present
  if ( slug ) {
    router.push( {
      path: slug,
    } )
  }
} )

export default router
